<template>
  <div class="row">
    <div class="card col-3">
      <div class="card-body">
        <h4> VARS 2 FACTOR AUTHENTICATION </h4>
        <p> Please enter your One Time Password </p>
          <input type="text" v-on:keyup.enter="authenticate" v-model="otp" maxlength="6" class="form-control">
          <p v-if="error" class="text-danger"> Failed to authenticate </p>
          <div class="div-center">
            <button @click="authenticate" class="btn btn-primary m-t-md"> Submit </button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../../services/auth.service';

export default {
    name: "Authenticate",
    props: {
        route: String
    },
    data() {
        return {
            otp: null,
            error: false,
            waiting: false
        }
    },
    methods: {
        authenticate() {
            AuthService.verify2fa(this.otp)
            .then(() => {
                this.waiting = false;
                this.$success("Successfully validated your 2FA");
                if(this.route !== null && this.route !== undefined) {
                    this.$router.push(this.route);
                } else {
                    // this.$router.push({name: '/'});
                    console.log('inside else');
                    this.$router.go(-1);
                }
            })
            .catch(() => {
                this.$error("Failed to validate your 2FA");
                this.error = true;
                this.waiting = true;
            })
        }
    },
    mounted() {
      console.log(this.route);
    }
}
</script>

<style scoped>
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
  }

  .div-center {
    width: fit-content;
    margin: auto;
  }

  @media(max-width: 1440px) {
    .col-3 {
      width: 45%;
    }
  }
</style>